import { useLocation } from "@remix-run/react";
import { useEffect, useState } from "react";
import Container from "~/components/layout/Container";
import { Icon } from "~/components/ui/icon";
import { Social } from "./Social";

export default function Header() {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);
  const location = useLocation();
  const theme = location.pathname == '/' ? 'light' : 'dark';
  return (
    <header className="flex flex-wrap absolute top-0 left-0 right-0 z-20">
      <div className="flex w-full bg-[#f6f5f5] py-1">
        <Container className="flex items-center justify-between">
          <div className="flex items-center justify-between md:justify-start w-full md:w-1/2 font-raleway">
            <a href="mailto:info@utbsupport.com" className="text-sm mr-2 text-gray-500"><Icon name="bxs:envelope" /> support@utbtrade.com</a>
            <a href="tel:+903326277777" className="text-sm text-gray-500"><Icon name="material-symbols:phone-in-talk-watchface-indicator-sharp" /> +90 332 627 77 77</a>
          </div>
          <div className="w-1/2 hidden md:flex items-center justify-end [&>a]:text-gray-500">
            <Social />
          </div>
        </Container>
      </div>
      <div className="flex w-full justify-between " style={{ backgroundColor: theme == 'dark' ? '#000000' : isMobile ? '#000000' : '#00000080' }}>
        <Container className="flex items-center justify-between py-3">
          <div className="flex items-center">
            <a href='/'><img src="/assets/utb-trade-support-logo.svg" alt="UTB Logo" className="h-[60px] w-[220px]" /></a>
          </div>
          <button
            className="md:hidden text-white"
            onClick={() => setIsOpen(!isOpen)}
          >
            <Icon name="solar:hamburger-menu-broken" className="text-white w-10 h-10" />
          </button>
          <nav className={`fixed md:relative top-0 md:top-auto left-0 md:left-auto right-0 md:right-auto bottom-0 md:bottom-auto bg-[#00000099] md:!bg-[#ffffff00] backdrop-blur-sm md:!backdrop-blur-none z-50 space-x-4 text-white font-raleway font-bold text-xl md:text-[14px] overflow-x-auto ${isOpen ? 'block' : 'hidden'} md:flex`}>
            <button className="block md:hidden absolute top-4 right-2 px-2 py-1 mr-0" onClick={() => setIsOpen(false)}>
              <Icon name="pepicons-pop:times" className="!mr-0 text-black w-8 h-8" />
            </button>
            <a href='/' className="bg-white w-full block md:hidden p-2 mr-0"><img src="/assets/utb-trade-support-logo.svg" alt="UTB Logo" className="h-[60px] w-[220px]" /></a>
            <a href="/" className={`block md:inline-block text-center md:text-left my-4 md:my-0 hover:text-[#20b1d7] border-b border-gray-600/50 mr-0 pb-5 md:pb-0 md:mr-4 md:border-none ${location.pathname == '/' ? 'text-[#20b1d7] font-extrabold' : ''}`}>ANASAYFA</a>
            <a href="/hizmetlerimiz" className={`block md:inline-block text-center md:text-left my-4 md:my-0 hover:text-[#20b1d7] border-b pb-4 md:pb-0 border-gray-600/50 mr-0 md:mr-4 md:border-none ${location.pathname == '/hizmetlerimiz' ? 'text-[#20b1d7] font-extrabold' : ''}`}>HİZMETLERİMİZ</a>
            <a href="/kurumsal" className={`block md:inline-block text-center md:text-left my-4 md:my-0 hover:text-[#20b1d7] border-b pb-4 md:pb-0 border-gray-600/60 mr-0 md:mr-4 md:border-none ${location.pathname == '/kurumsal' ? 'text-[#20b1d7] font-extrabold' : ''}`}>KURUMSAL</a>
            <a href="/blog" className={`block md:inline-block text-center md:text-left my-4 md:my-0 hover:text-[#20b1d7] border-b pb-4 md:pb-0 border-gray-600/70 mr-0 md:mr-4 md:border-none ${location.pathname == '/blog' ? 'text-[#20b1d7] font-extrabold' : ''}`}>BLOG</a>
            <a href="/iletisim" className={`block md:inline-block text-center md:text-left my-4 md:my-0 hover:text-[#20b1d7] mr-0 md:mr-4 pb-4 md:pb-0 ${location.pathname == '/iletisim' ? 'text-[#20b1d7] font-extrabold' : ''}`}>İLETİŞİM</a>
          </nav>
        </Container>
      </div>
    </header>
  );
}