import { Icon } from '~/components/ui/icon'

export const Social = () => {
  return (
    <>
      <a href="https://facebook.com/utbsupport" className="hover:text-blue-400 text-[20px] mr-1"><Icon name="gg:facebook" /></a>
      <a href="https://linkedin.com/company/utbsupport" className="hover:text-blue-400 text-[20px] mr-1"><Icon name="ri:linkedin-fill" /></a>
      <a href="https://instagram.com/utbsupport" className="hover:text-blue-400 text-[20px]"><Icon name="mdi:instagram" /></a>
    </>
  )
}
